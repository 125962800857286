var site = site || {};

site.template = site.template || {};

(function ($) {
  Drupal.behaviors.mppSort = {
    attach: function (context) {
      var $sortContainer = $('.js-product-grid-sorted', context);
      var $sortSelect = $('.js-mpp-sort-menu', context);
      var $sortPromo = $('.product-grid__item--promo', $sortContainer);
      var $hasFranchiseTout = $('.js-has-franchise-tout', context);

      $sortPromo.removeAttr('tabindex');
      $sortSelect.on('change', function () {
        $sortContainer.mixItUp('sort', this.value);
      });

      $sortContainer.each(function () {
        var $productGrid = $(this).closest('.js-product-grid');
        var isProductGrid = !!$productGrid.length;
        var hasQuickshop = $productGrid.hasClass('product-grid--quickshop');
        var $items = $('.js-grid-item', this);
        var colCountData = $(this).data('grid-items-per-row');
        var $firstSortVal = $items.eq(0).data('promo-sort'); // getter
        var $this = $(this);

        // special franchise tout case
        // check for franchise tout and change sort promo value of the first tout
        // so this remains in position 1
        if ($hasFranchiseTout.length && $firstSortVal == 1) {
          $items.eq(0).attr('data-promo-sort', 3); // setter
        }

        // instantiate mixitup
        if (!$this.hasClass('mixitup-initialized')) {
          var $mixItUpGrid = $this.addClass('mixitup-initialized');
          var mixItUpArgs = {
            selectors: {
              target: '.js-product-grid-item--sortable',
              sort: '.js-mpp-sort-option'
            },
            layout: {
              display: 'block'
            }
          };

          $mixItUpGrid
            .on('mixEnd', function () {
              $mixItUpGrid.addClass('mixitup-loaded');
              // Rebuild equal heights:
              Drupal.behaviors.grids.equalHeightsGrids($productGrid);
              // Rebuild quickshop:
              if (hasQuickshop && Drupal.behaviors.quickshop) {
                Drupal.behaviors.quickshop.initGrid($productGrid);
              }
            })
            .on('mixitup.initialize', function () {
              $mixItUpGrid.mixItUp(mixItUpArgs);
            })
            .on('mixStart', function () {
              // Destroy quickshop:
              if (hasQuickshop && Drupal.behaviors.quickshop) {
                Drupal.behaviors.quickshop.reset($productGrid);
              }
            });

          // Init mixItUp
          $mixItUpGrid.mixItUp(mixItUpArgs);
        }
      });
    }
  };
})(jQuery);
